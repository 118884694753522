import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "./styles/layouts.scss";

ReactDOM.render(<App />, document.getElementById("app"));

serviceWorker.unregister();
