import React from 'react';
// import { ScaleLoader } from "react-spinners";
import {Card} from 'reactstrap';

import Logo from './Logo';

const img = new Image();

const Fallback = () => {
  img.src = './images/loader2.gif';
  return (
    <div className="fallback-spinner">
      <Card className="text-center">
        <Logo className="mb-sm-2 position-static" />
        <div className="p-sm-3">
          <img src={img.src} alt="loader" height={150} />
        </div>
      </Card>
    </div>
  );
};

export default Fallback;
