import React from "react";
import PropTypes from "prop-types";

const img = new Image();

const Logo = ({ alt, className, imgSrc }) => {
  img.src = "/images/logo-transparent.png";
  return (
    <div className={`logo  ${className}`.trim()}>
      <img className="logo__img" src={imgSrc ? imgSrc : img.src} alt={alt} />
    </div>
  );
};

Logo.propTypes = {
  alt: PropTypes.string,
  imgSrc: PropTypes.string,
  className: PropTypes.string
};

Logo.defaultProps = {
  alt: "Halakoom",
  className: ""
};

export default Logo;
