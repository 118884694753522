import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import FallbackSpinner from './components/common/FallbackSpinner';

// Private Routes
const Home = React.lazy(() => import('./containers/Home'));
const LogOut = React.lazy(() => import('./containers/LogOut'));

// ADDON
const CreateAddon = React.lazy(() => import('./containers/addon/CreateAddon'));
const Addons = React.lazy(() => import('./containers/addon/Addons'));
const AddonDetails = React.lazy(() =>
  import('./containers/addon/AddonDetails')
);
const UpdateAddon = React.lazy(() => import('./containers/addon/UpdateAddon'));
// MENU
const CreateRequest = React.lazy(() =>
  import('./containers/request/CreateRequest')
);
const MenuRequests = React.lazy(() =>
  import('./containers/request/MenuRequests')
);
const MenuRequestsDetails = React.lazy(() =>
  import('./containers/request/MenuRequestsDetails')
);
const UpdateMenuRequest = React.lazy(() =>
  import('./containers/request/UpdateMenuRequest')
);
const PrivateRoute = React.lazy(() => import('./containers/PrivateRoute'));

//Product;
const Products = React.lazy(() => import('./containers/product/Products'));

const ProductDetails = React.lazy(() =>
  import('./containers/product/ProductDetails')
);
const UpdateProduct = React.lazy(() =>
  import('./containers/product/UpdateProduct')
);

// CODE PROMO

const CodePromos = React.lazy(() =>
  import('./containers/codePromos/Promotions')
);
const PromotionDetails = React.lazy(() =>
  import('./containers/codePromos/PromotionDetails')
);
const CreatePromotion = React.lazy(() =>
  import('./containers/codePromos/CreatePromotion')
);
const UpdatePromotion = React.lazy(() =>
  import('./containers/codePromos/UpdatePromotion')
);
// Notifications
const Notifications = React.lazy(() => import('./containers/Notifications'));
// Settings
const Settings = React.lazy(() => import('./containers/Settings'));
// Order Historics
const OrdersHistoric = React.lazy(() => import('./containers/OrdersHistoric'));
const OrderDetailsHistoric = React.lazy(() =>
  import('./components/historic/OrderDetailsHistoric')
);
// Holidays
const Holidays = React.lazy(() => import('./containers/Holidays/Holidays'));

const CreateHoliday = React.lazy(() =>
  import('./containers/Holidays/CreateHoliday')
);

const UpdateHoliday = React.lazy(() =>
  import('./containers/Holidays/UpdateHoliday')
);

// Public Routes
const Login = React.lazy(() => import('./containers/Login'));

//PROFILE

const ChangePassword = React.lazy(() => import('./containers/ChangePassword'));
const Profile = React.lazy(() => import('./containers/Profile'));

// PAYMENT

const Transaction = React.lazy(() =>
  import('./containers/payment/Transaction')
);
const StoreTransactionDetails = React.lazy(() =>
  import('./containers/payment/StoreTransactionDetails')
);

const Payout = React.lazy(() => import('./containers/payment/Payout'));

const PayoutDetails = React.lazy(() =>
  import('./containers/payment/PayoutDetails')
);

const TransferError = React.lazy(() =>
  import('./containers/payment/TransferError')
);

const publicRoutes = [
  {
    path: '/connexion/:storeToken?',
    component: Login,
    exact: true,
  },
];

const privateRoutes = [
  // POMOTIONS
  {
    path: '/code-promos/pages/:page',
    component: CodePromos,
    exact: true,
  },
  {
    path: '/code-promos/modifier/:id',
    component: UpdatePromotion,
    exact: true,
  },
  {
    path: '/code-promos/nouveau',
    component: CreatePromotion,
    exact: true,
  },
  {
    path: '/code-promos/details/:id',
    component: PromotionDetails,
    exact: true,
  }, //

  {
    path: '/(|accueil)/',
    component: Home,
    exact: true,
  },
  {
    path: '/deconnexion',
    component: LogOut,
  },

  //PROFILE
  {
    path: '/accueil/changer-mot-de-passe',
    component: ChangePassword,
    exact: true,
  },
  {
    path: '/accueil/profil',
    component: Profile,
    exact: true,
  },
  // ADDONS
  {
    path: '/supplements/nouveau',
    component: CreateAddon,
    exact: true,
  },
  {
    path: '/supplements/modifier/:id',
    component: UpdateAddon,
    exact: true,
  },
  {
    path: '/supplements/pages/:page',
    component: Addons,
    exact: true,
  },
  {
    path: '/supplements/details/:id',
    component: AddonDetails,
    exact: true,
  },
  //Menu
  {
    path: '/demandes/details/:id',
    component: MenuRequestsDetails,
    exact: true,
  },
  {
    path: '/demandes/pages/:page',
    component: MenuRequests,
    exact: true,
  },
  {
    path: '/demandes/nouveau',
    component: CreateRequest,
    exact: true,
  },
  {
    path: '/demandes/modifier/:id',
    component: UpdateMenuRequest,
    exact: true,
  },
  //Produit
  {
    path: '/produits',
    // component: Products,
    component: Products,
    exact: true,
  },
  {
    path: '/produits/details/:id',
    component: ProductDetails,
    exact: true,
  },
  {
    path: '/produits/modifier/:id',
    component: UpdateProduct,
    exact: true,
  },
  //Notifications
  {
    path: '/accueil/notifications',
    component: Notifications,
    exact: true,
  },
  // Order Historics
  {
    path: '/historique-des-commandes/pages/:page',
    component: OrdersHistoric,
    exact: true,
  },
  {
    path: '/historique-des-commandes/details/:id',
    component: OrderDetailsHistoric,
    exact: true,
  },
  // Settings
  {
    path: '/parametres',
    component: Settings,
    exact: true,
  },
  // Holidays
  {
    path: '/fermetures',
    component: Holidays,
    exact: true,
  },
  {
    path: '/fermetures/nouveau',
    component: CreateHoliday,
    exact: true,
  },
  {
    path: '/fermetures/modifier/:id',
    component: UpdateHoliday,
    exact: true,
  },
  // PAYMENT
  {
    path: '/transactions/pages/:page',
    component: Transaction,
    exact: true,
  },
  {
    path: '/transactions/details/:id',
    component: StoreTransactionDetails,
    exact: true,
  },
  {
    path: '/virements/pages/:page',
    component: Payout,
    exact: true,
  },
  {
    path: '/virements/details/:id',
    component: PayoutDetails,
    exact: true,
  },
  {
    path: '/transferts-bloqués/pages/:page',
    component: TransferError,
    exact: true,
  },
];

class Routes extends React.PureComponent {
  render() {
    return (
      <React.Suspense fallback={<FallbackSpinner />}>
        <HashRouter>
          <Switch>
            {publicRoutes.map(route => (
              <Route key={route.path} {...route} />
            ))}
            <PrivateRoute>
              <Switch>
                {privateRoutes.map(route => (
                  <Route key={route.path} {...route} />
                ))}
              </Switch>
            </PrivateRoute>
          </Switch>
        </HashRouter>
      </React.Suspense>
    );
  }
}

export default Routes;
