import { COUNT_NOTIFICATION } from "./queries.local";

export default {
  Query: {
    isLoggedIn: (_, {}, { getCacheKey }) =>
      getCacheKey({ __typename: "IsLoggedIn" }),
    menuRequestItems: ({ id }, {}, { getCacheKey }) =>
      getCacheKey({ __typename: "MenuRequestItems", id }),
    removedItems: (_, {}, { getCacheKey }) =>
      getCacheKey({ __typename: "RemovedItems" })
  },
  Mutation: {
    incrementNotificationsLength: (_, { looked }, { cache }) => {
      const audio = new Audio("/notification.mp3");
      audio.play();
      const queryParams = {
        query: COUNT_NOTIFICATION,
        variables: {
          looked
        }
      };
      const { countNotifications } = cache.readQuery(queryParams);
      cache.writeQuery({
        ...queryParams,
        data: {
          countNotifications: countNotifications + 1
        }
      });
      return null;
    },
    updateNotificationsLength: (_, { length, looked }, { cache }) => {
      cache.writeQuery({
        query: COUNT_NOTIFICATION,
        variables: {
          looked
        },
        data: {
          countNotifications: length
        }
      });
      return null;
    },
    setToken: (_, { token, useSession = false }, { cache }) => {
      const bearerToken = token ? `Bearer ${token}` : null;
      if (!bearerToken) {
        localStorage.removeItem("token");
        sessionStorage.removeItem("token");
        sessionStorage.clear();
      } else {
        if (!useSession) localStorage.setItem("token", bearerToken);
        else {
          sessionStorage.clear();
          sessionStorage.setItem("token", bearerToken);
        }
      }
      cache.writeData({
        data: {
          isLoggedIn: {
            __typename: "IsLoggedIn",
            isLoggedIn: !!token
          }
        }
      });
      return null;
    }
  }
};
